.penalty-board {
  width: 100%;
  background-color: #0d0c1b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-bottom: 5px solid white;
}

.penalty-left-div {
  text-align: center;
}

.penalty-board .penalty-div .label {
  font-size: 17px;
}
