.table_outer_div_winner .header-txt {
  background-color: #db2828 !important;
}

.finished-score-board .container {
  padding: 0px;
}

.finished-score-board .container .table_outer_div_match {
  border-radius: 10px;
}

.finished-score-board .container .table_outer_div_match .header-txt {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
