.table_outer_div .table-footer {
  padding-bottom: 10px;
  padding-left: 10px;
}

.player-add-btn {
  width: 100%;
}

.player-start-match-btn {
  text-align: center;
  background: #3fb144;
  border: 1px solid #3fb144;
  border-radius: 7px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  float: right;
  font-size: 17px;
}

.player-start-match-btn:hover {
  background: #ffffff;
  border: 1px solid #3fb144;
  color: #3fb144;
}

.player-start-match-btn:disabled {
  background: #ffffff;
  border: 1px solid #3fb144;
  color: #3fb144;
  opacity: 0.5;
  cursor: context-menu;
}
