.timer {
  margin: 3rem 0;
  width: 100%;
  display: flex;
  /* height: 12%; */
  justify-content: center;
  align-items: center;
}

.digits {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 3rem;
  color: #f5f5f5;
}

.mili-sec {
  color: #e42a2a;
}
