.match-card {
  border-right: 1px solid #edeef0;
  border-bottom: 1px solid #edeef0;
  padding: 10px;
  background-color: white;
}

.match-card h5 {
  text-transform: uppercase;
  margin-bottom: 0px;
}

.match-card p {
  color: #cbccce;
}

.team-div h4 {
  display: inline;
}

.team-div img {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  position: relative;
  top: -1.5px;
  display: inline;
}

.match-card .match-yet {
  margin-top: 5px;
  color: #cbccce;
}

.match-btn-section {
  text-align: center;
  padding: 15px;
}

.match-btn-section .match-summary {
  border: 1px solid #aeb0b1;
  padding: 5px 20px;
  border-radius: 25px;
  color: rgb(156, 156, 156);
  font-weight: 700;
  background-color: transparent;
  margin: 5px;
}

.match-btn-section .match-summary:hover {
  border: 1px solid #646566;
  background-color: #a4a6a8;
  color: black;
}

.team-div {
  margin-bottom: 3px;
}

.a-tab-border-bottom {
  border-bottom-style: dotted;
}
