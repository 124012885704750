.add-goal-div {
  width: 100%;
  background-color: #009e6f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  cursor: pointer;
  border: 5px solid #009e6f;
}

.add-goal-div:hover {
  background-color: white;
  color: #009e6f;
  border: 5px solid #009e6f;
}

.add-goal-div-disable {
  background: #ffffff;
  border: 1px solid #3fb144;
  color: #3fb144;
  opacity: 0.5;
  cursor: context-menu;
  pointer-events: none;
}
