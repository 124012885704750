.logout-head a {
  color: var(--main-color);
  font-weight: 700;
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .profile-bar-menu {
    width: 100vw !important;
  }
}

@media only screen and (min-width: 425px) {
  .profile-bar-menu {
    width: 425px !important;
  }
}

.profile-bar-menu {
  transition: width 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 110 !important;
}

.profile-bar-menu-hide {
  width: 0px !important;
}

.profile-bar-menu .close-icon {
  color: var(--main-color);
  font-size: 25px;
  float: right;
  margin: 10px 15px;
  cursor: pointer;
}

.profile-bar-menu .hide-menu-content {
  display: none !important;
}

.logout-btn {
  position: absolute !important;
  bottom: 10px !important;
}

.unclickable-overlay-div-profile {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
}
