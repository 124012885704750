.match-schedule-div .col-sm-6 {
  padding: 0px;
}

.match-schedule-div {
  /* box-shadow: 1px 1px 6px rgb(0 0 0 / 16%); */
  border-radius: 5px;
  /* background-color: white; */
}

.match-schedule-div .match-card:first-child {
  border-radius: 10px;
}

.match-schedule-btn-div {
  background: #fff;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
  text-align: center;
  margin: 5px;
  border-radius: 10px;
}

.match-schedule-btn-div .tab-btn:first-child {
  border-right: 1px solid #aeb0b1;
  border-bottom-left-radius: 10px;
}

.match-schedule-btn-div .tab-btn:last-child {
  border-bottom-right-radius: 10px;
}

.match-schedule-btn-div .tab-btn {
  padding: 10px;
  cursor: pointer;
}

.match-schedule-btn-div .tab-btn:hover {
  color: rgb(45, 143, 235);
}

.match-schedule-btn-div .tab-btn-active {
  border-bottom: 3px solid red;
}

.match-schedule-btn-div .tab-btn-live {
  padding: 10px;
}
