.card-h-div {
  text-align: center;
  color: white;
  text-decoration: none;
  background-color: var(--main-color);
  min-height: 200px;
}

.card-h-div:hover {
  background-color: var(--theme-dark-color);
  color: white;
}
