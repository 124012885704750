.score-board {
  width: 100%;
  background-color: #0d0c1b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-bottom: 5px solid white;
}

.score-left-div {
  text-align: center;
}

.score-board .goal-number .label {
  font-size: 40px;
}

.score-board .shot-number .label {
  font-size: 25px;
}
