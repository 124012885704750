.match-finish-btn {
  text-align: center;
  background: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 7px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  float: right;
  font-size: 17px;
}
