.Control-Buttons {
  margin: 3rem 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-grp {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btn-cont {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 100px;
  height: 35px;
  border-radius: 14px;
  margin: 0px 6px;
  display: flex;
  border: 2px solid #e42a2a;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #f5f5f5;
}

.btn-one {
  background-color: #e42a2a;
}
